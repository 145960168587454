export default [
    {
        path: "/change-logs",
        redirect: "/change-logs/all",
        meta: { roles: [1] },
        component: () => import("src/components/Dashboard/Layout/DashboardLayout.vue"),
        children: [
            {
                path: "all",
                name: "ListChangeLogs",
                component: () => import("src/components/Dashboard/Views/Logs/Admin-Change-Log.vue"),
                meta: {
                    pageTitle: 'Change logs'
                }
            }
        ]
    }
];