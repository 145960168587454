import axios from 'src/store/api/app';

export default {

    namespaced: true,

    actions: {
        async fetchAllAdminChangeLogs({ commit }, payload) {
            try {
                const response = await axios.get(`/api/admin/change-logs`, payload);
                return response.data.data;
            } catch (error) {
                throw error;
            }
        }
    }
}
